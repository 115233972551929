<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer top="5vh"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-form title="" ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房型名称" prop="templateName">
                    <el-input v-model="formPublish.templateName" placeholder="请填写房型名称（15个字以内）"  @blur="duplicateCheck"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item prop="roomSize" label="室：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.roomSize" :min="0"/>
                </el-form-item>
                <el-form-item prop="officeSize" label="厅：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.officeSize" :min="0"/>
                </el-form-item>
                <el-form-item prop="kitchenSize" label="厨：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.kitchenSize" :min="0"/>
                </el-form-item>
                <el-form-item prop="toiletSize" label="卫：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.toiletSize" :min="0"/>
                </el-form-item>
            </div>
            <div class="flex">
                <!--<el-form-item label="房型面积" prop="area">
                     <el-input type="number" v-model="formPublish.area" :min="0" placeholder="房型面积"></el-input>
                </el-form-item>-->
                <el-form-item label="房屋朝向" prop="houseOrientation">
                    <el-select v-model="formPublish.houseOrientation" placeholder="房屋朝向">
                        <el-option v-for="item in orientations" :key="item.uuid" :label="item.codeName"
                                   :value="item.codeName"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="装修风格" prop="decorationStyle">
                    <el-select v-model="formPublish.decorationStyle" placeholder="装修风格">
                        <el-option v-for="item in decorationStyles" :key="item.uuid" :label="item.codeName"
                                   :value="item.codeName"/>
                    </el-select>
                </el-form-item>
            </div>
            <!--<div class="flex">
                 &lt;!&ndash;<el-form-item label="月租金额" prop="minAmount" class="amount">
                      <el-input type="number" v-model="formPublish.minAmount" :min="0"
                                placeholder="最低租金"></el-input>
                      <i class="el-icon-minus" style="margin: 0 10px"></i>
                      <el-input type="number" v-model="formPublish.maxAmount"
                                :min="formPublish.minAmount" placeholder="最高租金"></el-input>
                 </el-form-item>&ndash;&gt;

            </div>-->
            <!--<div class="flex">
                 <el-form-item label="管家姓名" prop="houseManageName">
                      <el-select v-model="formPublish.houseManageName" placeholder="请选择管家姓名"
                                 @change="handleChange" clearable filterable>
                           <el-option
                                   v-for="item in users"
                                   :key="item.username"
                                   :label="item.fullname"
                                   :value="item.username"
                           ></el-option>
                      </el-select>
                 </el-form-item>
                 <el-form-item label="管家手机号" prop="houseManagePhone" label-width="130px">
                      <el-input v-model="formPublish.houseManagePhone" placeholder="管家手机号码"></el-input>
                 </el-form-item>
            </div>-->
            <div class="flex">
                <el-form-item label="付款方式" prop="paymentMethod">
                    <!--<el-select v-model="formPublish.paymentMethod" placeholder="付款方式">
                         <el-option
                                 v-for="item in paymentMethods"
                                 :key="item.uuid"
                                 :label="item.codeName"
                                 :value="item.codeName"
                         ></el-option>
                    </el-select>-->
                    <el-input v-model="formPublish.paymentMethod" disabled placeholder="付款方式"/>


                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房屋标签" prop="labels">
                    <div class="tag-group">
                        <el-tag @click="handleClickLabel" type="info">全选</el-tag>
                        <el-tag @click="handleClickLabelTag(item)" v-for="item in labels" :key="item.code"
                                :type="item.type" effect="plain">
                            {{ item.codeName }}
                        </el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房间配置" prop="configs">
                    <div class="tag-group">
                        <el-tag @click="handleClickConfig" type="info">全选</el-tag>
                        <el-tag @click="handleClickConfigTag(item)" v-for="item in configs" :key="item.code"
                                :type="item.type" effect="plain">
                            {{ item.codeName }}
                        </el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="便利设施" prop="amenities">
                    <div class="tag-group">
                        <el-tag @click="handleClickAmenities" type="info">全选</el-tag>
                        <el-tag @click="handleClickAmenitiesTag(item)" v-for="item in amenities" :key="item.code"
                                :type="item.type" effect="plain">
                            {{ item.codeName }}
                        </el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="安全设施" prop="safety">
                    <div class="tag-group">
                        <el-tag @click="handleClickSafety" type="info">全选</el-tag>
                        <el-tag @click="handleClickSafetyTag(item)" v-for="item in safety" :key="item.code"
                                :type="item.type" effect="plain">
                            {{ item.codeName }}
                        </el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房屋描述" prop="houseDescription">
                    <el-input type="textarea" :rows="5" maxlength="500" show-word-limit
                              v-model="formPublish.houseDescription"
                              placeholder="请在这里输入当前房型的描述（字数要求50~500）"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房间照片" prop="samplePhotos" class="samplePhotos">
                    <upload-pictureCard :uuidList="formPublish.fileList" :limit="24" @on-success="handleSuccess"
                                        @on-remove="handleRemove"/>
                </el-form-item>
            </div>
            <el-alert type="info" :closable="false">
                <i class="el-icon-warning"></i>
                （请务必上传真实照片以便我们审核您的信息）<br>
                1.第一张为封面图，封面图只能是客厅、卧室其中之一<br>
                2.建议尺寸：1366*768，最大5M；仅支持jpg、png格式<br>
                3.照片数量：最少5张，最多24张
            </el-alert>
            <div class="flex">
                <el-form-item label="房间视频" prop="videoUrl" class="samplePhotos">
                <upload-video  accept=".mp4" @delete-video="deleteUploadedVideo" @on-success="handleUploadSuccess" :uuid="formPublish.videoUrl"/>
                </el-form-item>

            </div>
            <el-alert type="info" :closable="false">
                <i class="el-icon-warning"></i>
                （请务必上传真实视频以便我们审核您的信息）<br>
            </el-alert>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {formPublishRules} from "@/views/rental-management/publish-create/data";
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import uploadVideo from "@/components/Upload/video-upload-perfect.vue";
import * as publishManagementApi from "@/api/publish";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {getUserListApi,getDuplicateCheckApi} from "@/api/publish";

export default {
    name: "layer-add-edit-publish",
    data() {
        return {
            addEdit: "add",
            dialogTitle: "新增房型",
            dialogVisible: false,
            formPublish: {
                templateName:'',
                paymentMethod: "月付",
                labels: [],
                configs: [],
                samplePhotos: [],
                amenities: [],
                safety: [],
                videoUrl: '',
                fileList: [],
            },
            orientations: [],
            decorationStyles: [],
            paymentMethods: [],
            labels: [],
            configs: [],
            amenities: [],
            safety: [],
            users: [],
            dialogImageUrl: "",
            dialogVisible2: false,
            fileList: [{name: "food.jpeg", url: ""}],
            rules: formPublishRules(),
            videoUrl: ''
        };
    },
    components: {uploadPictureCard, uploadVideo},
    async mounted() {
        this.orientations = await this.$store.dispatch("app/getDictionaryByCodeActions", "300000");
        this.decorationStyles = await this.$store.dispatch("app/getDictionaryByCodeActions", "400000");
        //this.paymentMethods=await this.$store.dispatch("app/getDictionaryByCodeActions", "500000");

        /* getByParent("34bbe05e930f495e95e282ca01b293e4").then(res => {
            this.orientations = res.list;
        });
        getByParent("0f2c6da433cb4a31b67cff4ad4df5edc").then(res => {
            this.decorationStyles = res.list;
        });
        getByParent("f5df89924df6436187c90368520ec5be").then(res => {
            this.paymentMethods = res.list;
        });*/
        /* getUserListApi().then(res => {
            // console.log(res.list.map(v=>v.username).sort());
            this.users = res.list;
        }); */

        let users = await getUserListApi();
        this.users = users.list;

    },
    methods: {
        //新增修改打开弹窗
        layerAddEditPublishInit(addEdit, row) {
            // 页面初始化，父页面传递数据
            this.addEdit = addEdit;
            //打开弹窗
            this.dialogVisible = true;
            //根据操作类型判断展示标题文字
            this.dialogTitle = addEdit === "add" ? "新增房型" : "查看房型";

            if (addEdit === "add") {
                row = {paymentMethod: "月付", labels: [], configs: [], samplePhotos: [], amenities: [], safety: []};
            }
            this.editPublishInit(row);
        },
        // 从发布列表打开弹框
        layerAddEditPublishInit2(row) {
            this.addEdit = "edit";
            this.dialogVisible = true;
            this.dialogTitle = "查看房型";
            publishManagementApi.getByApartmentUuid(row.uuid).then(res => {
                this.editPublishInit(res.info);
            });
        },
        async editPublishInit(row) {
            // 编辑页面回显数据
            if (this.addEdit === "edit") {
                row.fileList = row.samplePhotos.split(",");
            } else {
                row.fileList = [];
            }

            this.formPublish = row;
            this.formPublish.paymentMethod = "月付";
            let labels = await this.$store.dispatch("app/getDictionaryByCodeActions", "100000");
            labels.forEach(v => {
                v.type = this.addEdit === "add" ? "info" :
                    this.formPublish.labels.some(v2 => v2.code === v.code) ? "" : "info";
            });
            this.labels = labels;
            let configs = await this.$store.dispatch("app/getDictionaryByCodeActions", "200000");
            configs.forEach(v => {
                v.type = this.addEdit === "add" ? "info" :
                    this.formPublish.configs.some(v2 => v2.code === v.code) ? "" : "info";
            });
            this.configs = configs;
            let amenities = await this.$store.dispatch("app/getDictionaryByCodeActions", "200200");
            amenities.forEach(v => {
                v.type = this.addEdit === "add" ? "info" :
                    this.formPublish.amenities.some(v2 => v2.code === v.code) ? "" : "info";
            });
            this.amenities = amenities;
            let safety = await this.$store.dispatch("app/getDictionaryByCodeActions", "200100");
            safety.forEach(v => {
                v.type = this.addEdit === "add" ? "info" :
                    this.formPublish.safety.some(v2 => v2.code === v.code) ? "" : "info";
            });
            this.safety = safety;
            /* getByParent("b3dc4f32b8f14182a679b4862d224a40").then(res => {
                res.list.forEach(v => {
                    v.type = this.addEdit === "add" ? "info" :
                        this.formPublish.labels.some(v2 => v2.code === v.code) ? "" : "info";
                });
                this.labels = res.list;
                console.log("lables", this.labels);
            });
            getByParent("95f9785e44574aefb9094283acc58113").then(res => {
                res.list.forEach(v => {
                    v.type = this.addEdit === "add" ? "info" :
                        this.formPublish.configs.some(v2 => v2.code === v.code) ? "" : "info";
                });
                this.configs = res.list;
            }); */
            /* getByParent("ac840801a7df48f480b6a637bc5590cd").then(res => {
                res.list.forEach(v => {
                    v.type = this.addEdit === "add" ? "info" :
                        this.formPublish.amenities.some(v2 => v2.code === v.code) ? "" : "info";
                });
                this.amenities = res.list;
                console.log("amenities", this.amenities);
            }); */
            /* getByParent("b18b0fe5fe1a454692cc95fb7096c702").then(res => {
                res.list.forEach(v => {
                    v.type = this.addEdit === "add" ? "info" :
                        this.formPublish.safety.some(v2 => v2.code === v.code) ? "" : "info";
                });
                this.safety = res.list;
            }); */
        },
        handleChange(e) {
            const {mobile} = this.users.find(v => v.username === e);
            this.formPublish.houseManagePhone = mobile;
        },
        handleClickLabel(e) {
            e.target.classList.toggle("el-tag--info");
            this.formPublish.labels = [];
            let f = e.target.classList.contains("el-tag--info");
            this.labels.forEach(v => {
                v.type = f ? "info" : "";
                if (!f) this.formPublish.labels.push({uuid: v.uuid});
            });
        },
        handleClickConfig(e) {
            e.target.classList.toggle("el-tag--info");
            this.formPublish.configs = [];
            let f = e.target.classList.contains("el-tag--info");
            this.configs.forEach(v => {
                v.type = f ? "info" : "";
                if (!f) this.formPublish.configs.push({uuid: v.uuid});
            });
        },
        handleClickAmenities(e) {
            e.target.classList.toggle("el-tag--info");
            this.formPublish.amenities = [];
            let f = e.target.classList.contains("el-tag--info");
            this.amenities.forEach(v => {
                v.type = f ? "info" : "";
                if (!f) this.formPublish.amenities.push({uuid: v.uuid});
            });
        },
        handleClickSafety(e) {
            e.target.classList.toggle("el-tag--info");
            this.formPublish.safety = [];
            let f = e.target.classList.contains("el-tag--info");
            this.safety.forEach(v => {
                v.type = f ? "info" : "";
                if (!f) this.formPublish.safety.push({uuid: v.uuid});
            });
            console.log(this.formPublish.safety);
        },
        handleClickLabelTag(e) {
            e.type = e.type ? "" : "info";
            if (e.type) this.formPublish.labels = this.formPublish.labels.filter(v => v.uuid !== e.uuid);
            else this.formPublish.labels.push({uuid: e.uuid});
        },
        handleClickConfigTag(e) {
            e.type = e.type ? "" : "info";
            if (e.type) this.formPublish.configs = this.formPublish.configs.filter(v => v.uuid !== e.uuid);
            else this.formPublish.configs.push({uuid: e.uuid});
        },
        handleClickAmenitiesTag(e) {
            e.type = e.type ? "" : "info";
            if (e.type) this.formPublish.amenities = this.formPublish.amenities.filter(v => v.uuid !== e.uuid);
            else this.formPublish.amenities.push({uuid: e.uuid});
        },
        handleClickSafetyTag(e) {
            e.type = e.type ? "" : "info";
            if (e.type) this.formPublish.safety = this.formPublish.safety.filter(v => v.uuid !== e.uuid);
            else this.formPublish.safety.push({uuid: e.uuid});
        },
        handleError(err) {
            console.log(err);
        },
        handleSuccess(response) {
            const uuid = response.file.response.returnObject.info.uuid;
            this.formPublish.samplePhotos = this.formPublish.samplePhotos.length === 0 ? "" : this.formPublish.samplePhotos;

            //2022/03/22 王江毅 图片上传后 将保存图片的字段拆分(拆分后判断长度等不等于0)后过滤(过滤空字符串，如果以空字符串进行拆分会返回长度为1的数组,且该值为空字符串)
            const isTrue = this.formPublish.samplePhotos.split(",").filter(item => item !== '').length !== 0;

            this.formPublish.samplePhotos += isTrue ? ("," + uuid) : uuid;
        },

        handleRemove({fileList}) {
            const fileListName = fileList.map(v => v.name.split(".")[0]);
            this.formPublish.samplePhotos = fileListName.join(",");
            this.formPublish.fileList = this.formPublish.samplePhotos.split(",").filter(item => item !== '');
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 24 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleSubmitPublish() {
            // 表单提交
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    this.formPublish.samplePhotos = this.formPublish.samplePhotos.toString();
                    const request = this.addEdit === "add" ? "addPublishApi" : "editPublishApi";
                    let param = [];
                    if (this.addEdit === "edit") {
                        param.push(this.formPublish.uuid);
                    }
                    param.push(this.formPublish);
                    console.log('handleSubmitPublish',param);
                    const loadingOptions = this.loadingOptions;
                    const loading = this.$loading({...loadingOptions});
                    publishManagementApi[request](...param).then(() => {
                        MessageSuccess(`${this.dialogTitle}成功!`);
                        loading.close();
                    }).catch(err => {
                        loading.close();
                        console.log("err", err);
                        this.$refs["formPublish"].resetFields();
                    }).finally(() => {
                        this.handleCancel("update");
                        loading.close();
                    });
                } else {
                    MessageWarning("请填写必填值", 1000);
                    return false;
                }
            });
        },
        handleCancel(closeType = "cancel") {
            // 取消、关闭弹窗
            this.dialogVisible = false;
            this.formPublish = {
                paymentMethod: "月付", labels: [], configs: [], samplePhotos: [], amenities: [], safety: []
            };
            this.$refs["formPublish"].resetFields();
            // 新增、编辑成功，需要刷新数据
            if (closeType === "update") this.$parent.$refs.publishTableRef.getTableData();
        },

        handleUploadSuccess({info}) {
            // 上传视频
            this.videoUrl = info.uuid;
            console.log('this.videoUrl',this.videoUrl);
            this.formPublish.videoUrl = info.uuid;
        },
        //2024/04/17 张晓瑜新增删除已上传的视频
        deleteUploadedVideo() {
        this.formPublish.videoUrl = ''; // 清空视频 UUID
        console.log('this.formPublish.videoUrl0',this.formPublish.videoUrl);
        // this.isUploaded = false; // 标记未上传视频
        },

        // 2024/01/29 张晓瑜新增房型名称重复检查,名称是否存在
        duplicateCheck() {
            const templateName = this.formPublish.templateName;
            console.log('templateName', templateName);
            // 调用接口并将templateName作为参数传递给后端
            getDuplicateCheckApi({ templateName: templateName }).then(res => {
                if (res.info === true) {
                // 房型已存在的处理逻辑
                console.log('房型已存在');
                // 向用户显示提示信息
                this.$message.error('抱歉，该房型已存在');
                } else {
                // 房型不存在的处理逻辑
                console.log('房型不存在');
                // 向用户显示提示信息
                this.$message.success('该房型名称可用');
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .amount {
        .el-input {
            width: 42%;
        }
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}
</style>
<style>
.v-modal {
    z-index: 2000 !important;
}
</style>
